var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
const app = namespace('app');
// Define a super class component
let AbstractPage = class AbstractPage extends Vue {
    constructor() {
        super(...arguments);
        this.pageTitle = '';
    }
    head() {
        let title = this.pageTitle;
        if (!title) {
            if (this.$te(`${this.portal.key}.pageName`)) {
                title = this.$tc(`${this.portal.key}.pageName`);
            }
            else if (this.$te('pageName')) {
                title = this.$tc('pageName');
            }
            else if (this.$te(`${this.portal.key}.pageTitle`)) {
                title = this.$tc(`${this.portal.key}.pageTitle`);
            }
            else if (this.$te('pageTitle')) {
                title = this.$tc('pageTitle');
            }
            else {
                title = '';
            }
        }
        if (this.$config.appStage !== 'production') {
            title = '[TEST] ' + title;
        }
        return {
            title,
            titleTemplate: `%s - ${this.$t(`portals.${this.portal.key}.name`)}`,
            link: [
                { rel: 'apple-touch-icon', sizes: '180x180', href: `/icons/${this.portal.key}/apple-touch-icon.png?v=20250319` },
                { rel: 'icon', type: 'image/png', sizes: '32x32', href: `/icons/${this.portal.key}/favicon-32x32.png?v=20250319` },
                { rel: 'icon', type: 'image/png', sizes: '16x16', href: `/icons/${this.portal.key}/favicon-16x16.png?v=20250319` },
                { rel: 'manifest', href: `/icons/${this.portal.key}/site.webmanifest?v=20250319` },
                { rel: 'mask-icon', href: `/icons/${this.portal.key}/safari-pinned-tab.svg?v=20250319`, color: this.$vuetify.theme.currentTheme.primary },
                { rel: 'shortcut icon', href: `/icons/${this.portal.key}/favicon.ico?v=20250319` }
            ],
            meta: [
                { name: 'msapplication-TileColor', content: '#ffffff' },
                { name: 'msapplication-config', content: `/icons/${this.portal.key}/browserconfig.xml?v=20250319` },
                { hid: 'og:title', name: 'og:title', content: title },
                { hid: 'og:site_name', name: 'og:site_name', content: this.$tc(`portals.${this.portal.key}.name`) }
            ],
            htmlAttrs: {
                lang: 'de'
            }
        };
    }
};
__decorate([
    app.Getter
], AbstractPage.prototype, "portal", void 0);
AbstractPage = __decorate([
    Component({
        scrollToTop: true
    })
], AbstractPage);
export default AbstractPage;

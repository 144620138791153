import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _478f1927 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _e12ee622 = () => interopDefault(import('../pages/documents.vue' /* webpackChunkName: "pages/documents" */))
const _6a7f654f = () => interopDefault(import('../pages/documents/_area/index.vue' /* webpackChunkName: "pages/documents/_area/index" */))
const _4a831a65 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _70e98128 = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _580a22d4 = () => interopDefault(import('../pages/messages/create.vue' /* webpackChunkName: "pages/messages/create" */))
const _182d80dc = () => interopDefault(import('../pages/messages/inbox.vue' /* webpackChunkName: "pages/messages/inbox" */))
const _046e709c = () => interopDefault(import('../pages/messages/sent.vue' /* webpackChunkName: "pages/messages/sent" */))
const _6a353f20 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _f0c424ba = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _f3da4f36 = () => interopDefault(import('../pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _364d0646 = () => interopDefault(import('../pages/requests.vue' /* webpackChunkName: "pages/requests" */))
const _615a29a0 = () => interopDefault(import('../pages/requests/index.vue' /* webpackChunkName: "pages/requests/index" */))
const _64b4f61e = () => interopDefault(import('../pages/requests/new/index.vue' /* webpackChunkName: "pages/requests/new/index" */))
const _be32bb6e = () => interopDefault(import('../pages/requests/submitted.vue' /* webpackChunkName: "pages/requests/submitted" */))
const _4a46ae90 = () => interopDefault(import('../pages/requests/new/_type.vue' /* webpackChunkName: "pages/requests/new/_type" */))
const _6e0411ab = () => interopDefault(import('../pages/auth/impersonate.vue' /* webpackChunkName: "pages/auth/impersonate" */))
const _5e2973ca = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _cce2f470 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _6e56738c = () => interopDefault(import('../pages/auth/registration/index.vue' /* webpackChunkName: "pages/auth/registration/index" */))
const _3edf3eb0 = () => interopDefault(import('../pages/auth/t2t.vue' /* webpackChunkName: "pages/auth/t2t" */))
const _27c598f9 = () => interopDefault(import('../pages/download/document.vue' /* webpackChunkName: "pages/download/document" */))
const _c8a4a694 = () => interopDefault(import('../pages/idAustriaSp/callback.vue' /* webpackChunkName: "pages/idAustriaSp/callback" */))
const _1d1ccc26 = () => interopDefault(import('../pages/idAustriaSp/confirmation.vue' /* webpackChunkName: "pages/idAustriaSp/confirmation" */))
const _345bc5ac = () => interopDefault(import('../pages/idAustriaSp/verify.vue' /* webpackChunkName: "pages/idAustriaSp/verify" */))
const _16383511 = () => interopDefault(import('../pages/auth/registration/update.vue' /* webpackChunkName: "pages/auth/registration/update" */))
const _2b6da67e = () => interopDefault(import('../pages/auth/registration/verify.vue' /* webpackChunkName: "pages/auth/registration/verify" */))
const _55af30dc = () => interopDefault(import('../pages/auth/reset/password/index.vue' /* webpackChunkName: "pages/auth/reset/password/index" */))
const _30c55d49 = () => interopDefault(import('../pages/auth/reset/user.vue' /* webpackChunkName: "pages/auth/reset/user" */))
const _06a84c58 = () => interopDefault(import('../pages/auth/reset/username.vue' /* webpackChunkName: "pages/auth/reset/username" */))
const _3c73e51b = () => interopDefault(import('../pages/auth/sso/login.vue' /* webpackChunkName: "pages/auth/sso/login" */))
const _7f412a37 = () => interopDefault(import('../pages/auth/sso/verify.vue' /* webpackChunkName: "pages/auth/sso/verify" */))
const _7b8d7428 = () => interopDefault(import('../pages/profile/settings/delete.vue' /* webpackChunkName: "pages/profile/settings/delete" */))
const _3497862f = () => interopDefault(import('../pages/profile/settings/email.vue' /* webpackChunkName: "pages/profile/settings/email" */))
const _5c0febd8 = () => interopDefault(import('../pages/profile/settings/password.vue' /* webpackChunkName: "pages/profile/settings/password" */))
const _0e87d342 = () => interopDefault(import('../pages/auth/reset/password/verify.vue' /* webpackChunkName: "pages/auth/reset/password/verify" */))
const _60bb24ee = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4db3f762 = () => interopDefault(import('../pages/documents/detail/_id/index.vue' /* webpackChunkName: "pages/documents/detail/_id/index" */))
const _6a155199 = () => interopDefault(import('../pages/messages/detail/_id/index.vue' /* webpackChunkName: "pages/messages/detail/_id/index" */))
const _03712e21 = () => interopDefault(import('../pages/requests/detail/_id/index.vue' /* webpackChunkName: "pages/requests/detail/_id/index" */))
const _168f1e16 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _478f1927,
    name: "account___de"
  }, {
    path: "/documents",
    component: _e12ee622,
    name: "documents___de",
    children: [{
      path: ":area",
      component: _6a7f654f,
      name: "documents-area___de"
    }]
  }, {
    path: "/messages",
    component: _4a831a65,
    children: [{
      path: "",
      component: _70e98128,
      name: "messages___de"
    }, {
      path: "create",
      component: _580a22d4,
      name: "messages-create___de"
    }, {
      path: "inbox",
      component: _182d80dc,
      name: "messages-inbox___de"
    }, {
      path: "sent",
      component: _046e709c,
      name: "messages-sent___de"
    }]
  }, {
    path: "/profile",
    component: _6a353f20,
    children: [{
      path: "",
      component: _f0c424ba,
      name: "profile___de"
    }, {
      path: "settings",
      component: _f3da4f36,
      name: "profile-settings___de"
    }]
  }, {
    path: "/requests",
    component: _364d0646,
    children: [{
      path: "",
      component: _615a29a0,
      name: "requests___de"
    }, {
      path: "new",
      component: _64b4f61e,
      name: "requests-new___de"
    }, {
      path: "submitted",
      component: _be32bb6e,
      name: "requests-submitted___de"
    }, {
      path: "new/:type",
      component: _4a46ae90,
      name: "requests-new-type___de"
    }]
  }, {
    path: "/auth/impersonate",
    component: _6e0411ab,
    name: "auth-impersonate___de"
  }, {
    path: "/auth/login",
    component: _5e2973ca,
    name: "auth-login___de"
  }, {
    path: "/auth/logout",
    component: _cce2f470,
    name: "auth-logout___de"
  }, {
    path: "/auth/registration",
    component: _6e56738c,
    name: "auth-registration___de"
  }, {
    path: "/auth/t2t",
    component: _3edf3eb0,
    name: "auth-t2t___de"
  }, {
    path: "/download/document",
    component: _27c598f9,
    name: "download-document___de"
  }, {
    path: "/idAustriaSp/callback",
    component: _c8a4a694,
    name: "idAustriaSp-callback___de"
  }, {
    path: "/idAustriaSp/confirmation",
    component: _1d1ccc26,
    name: "idAustriaSp-confirmation___de"
  }, {
    path: "/idAustriaSp/verify",
    component: _345bc5ac,
    name: "idAustriaSp-verify___de"
  }, {
    path: "/auth/registration/update",
    component: _16383511,
    name: "auth-registration-update___de"
  }, {
    path: "/auth/registration/verify",
    component: _2b6da67e,
    name: "auth-registration-verify___de"
  }, {
    path: "/auth/reset/password",
    component: _55af30dc,
    name: "auth-reset-password___de"
  }, {
    path: "/auth/reset/user",
    component: _30c55d49,
    name: "auth-reset-user___de"
  }, {
    path: "/auth/reset/username",
    component: _06a84c58,
    name: "auth-reset-username___de"
  }, {
    path: "/auth/sso/login",
    component: _3c73e51b,
    name: "auth-sso-login___de"
  }, {
    path: "/auth/sso/verify",
    component: _7f412a37,
    name: "auth-sso-verify___de"
  }, {
    path: "/profile/settings/delete",
    component: _7b8d7428,
    name: "profile-settings-delete___de"
  }, {
    path: "/profile/settings/email",
    component: _3497862f,
    name: "profile-settings-email___de"
  }, {
    path: "/profile/settings/password",
    component: _5c0febd8,
    name: "profile-settings-password___de"
  }, {
    path: "/auth/reset/password/verify",
    component: _0e87d342,
    name: "auth-reset-password-verify___de"
  }, {
    path: "/",
    component: _60bb24ee,
    name: "index___de"
  }, {
    path: "/documents/detail/:id",
    component: _4db3f762,
    name: "documents-detail-id___de"
  }, {
    path: "/messages/detail/:id",
    component: _6a155199,
    name: "messages-detail-id___de"
  }, {
    path: "/requests/detail/:id",
    component: _03712e21,
    name: "requests-detail-id___de"
  }, {
    path: "/requests/new/:type?",
    component: _4a46ae90,
    name: "requests-new-type___de"
  }, {
    path: "/*",
    component: _168f1e16,
    name: "all___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

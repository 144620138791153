var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Ref, Watch } from 'nuxt-property-decorator';
import { namespace } from 'vuex-class';
import BookmarkHint from '~/components/global/BookmarkHint.vue';
import CookiesInfobox from '~/components/global/CookiesInfobox.vue';
import PageLoading from '~/components/global/PageLoading.vue';
import PortalInfobox from '~/components/global/PortalInfobox.vue';
import { PATHS } from '~/router';
import FooterNav from '~/components/nav/FooterNav.vue';
import MetaNav from '~/components/nav/MetaNav.vue';
import Sidebar from '~/components/global/Sidebar.vue';
import { appStore } from '~/utils/store-accessor';
const app = namespace('app');
const user = namespace('user');
const errorMiddleware = (ctx) => {
    var _a, _b, _c;
    if (ctx.route.path === PATHS.AUTH.LOGOUT) {
        return;
    }
    if (appStore.error) {
        ctx.error({
            statusCode: ((_a = appStore.error.response) === null || _a === void 0 ? void 0 : _a.status) || 404,
            message: ((_c = (_b = appStore.error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) || 'Oops... Something went wrong.'
        });
    }
};
let Default = class Default extends Vue {
    constructor() {
        super(...arguments);
        this.PATHS = PATHS;
        this.isSidebarVisible = null;
    }
    head() {
        require('~/assets/sass/style-vuetify.scss');
        require('~/assets/sass/style.scss');
        if (this.portal.key === 'bpk') {
            require('~/assets/sass/portals/bpk/style-vuetify.scss');
            require('~/assets/sass/portals/bpk/style.scss');
        }
        if (this.portal.key === 'pk') {
            require('~/assets/sass/portals/pk/style.scss');
        }
        return {};
    }
    updateFooterHeight() {
        this.$nextTick(() => {
            var _a;
            (_a = this.footer) === null || _a === void 0 ? void 0 : _a.callUpdate();
        });
    }
    mounted() {
        window.addEventListener('resize', () => {
            this.updateFooterHeight();
        });
    }
    onAppErrorChange() {
        errorMiddleware(this.$nuxt.context);
    }
};
__decorate([
    Ref()
], Default.prototype, "footer", void 0);
__decorate([
    app.State('error')
], Default.prototype, "appError", void 0);
__decorate([
    app.State
], Default.prototype, "isLoaded", void 0);
__decorate([
    app.Getter
], Default.prototype, "portal", void 0);
__decorate([
    app.Getter
], Default.prototype, "snackbar", void 0);
__decorate([
    app.Action
], Default.prototype, "hideSnackbar", void 0);
__decorate([
    user.Getter('canAccess')
], Default.prototype, "isUserLoggedIn", void 0);
__decorate([
    Watch('appError', { immediate: true })
], Default.prototype, "onAppErrorChange", null);
Default = __decorate([
    Component({
        components: {
            BookmarkHint,
            CookiesInfobox,
            PageLoading,
            PortalInfobox,
            FooterNav,
            MetaNav,
            Sidebar
        },
        middleware: [
            errorMiddleware
        ]
    })
], Default);
export default Default;
